import { forEach, isArray, isString, isEmpty } from "lodash-es";
import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
const getMessage = (t, key, value) => {
  const messages = {
    required: t(`form.required`),
    min: t(`form.min`, {
      num: value
    }),
    max: t(`form.max`, {
      num: value
    }),
    maxLength: t(`form.maxLength`, {
      num: value
    }),
    minLength: t(`form.minLength`, {
      num: value
    }),
    pattern: t("form.pattern"),
    email: t("form.invalid_email"),
    minFiles: t("form.too_few_files"),
    maxFiles: t("form.too_many_files"),
    sameAs: t("form.doesnt_match"),
    maxFileWeight: t(`form.file_too_big`, {
      num: value
    }),
    fileType: t("form.invalid_file")
  };
  return key in messages ? messages[key] : t(`form.${key}`);
};
const useFormValidation = (backendValidation = void 0, customValidations = void 0) => {
  const { t } = useTranslation("core");
  const { getValues } = useFormContext();
  const [validation, values] = useMemo(() => {
    if (!backendValidation) {
      return [{}, {}];
    }
    const creatingValidation = {};
    const creatingValues = {};
    let innerCustomValidations = {};
    forEach(backendValidation, ({ name, value, message }) => {
      switch (name) {
        case "email":
          innerCustomValidations = {
            ...innerCustomValidations,
            email: (currentValue) => {
              if (isString(currentValue) && !isEmpty(currentValue) && // eslint-disable-next-line prefer-named-capture-group -- no need to name groups
              !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
                currentValue
              )) {
                return message && !isEmpty(message) ? message : getMessage(t, name);
              }
            }
          };
          break;
        case "sameAs":
          innerCustomValidations = {
            ...innerCustomValidations,
            sameAs: (currentValue) => {
              const expectedValue = getValues(value);
              if (!expectedValue || isEmpty(expectedValue)) {
                return true;
              }
              if (expectedValue !== currentValue) {
                return message && !isEmpty(message) ? message : getMessage(t, name);
              }
            }
          };
          break;
        case "pattern":
          creatingValues[name] = isString(value) ? RegExp(value.replace(/\\\\/g, "\\")) : value;
          creatingValidation[name] = {
            value: creatingValues[name],
            message: message && !isEmpty(message) ? message : getMessage(t, name, value)
          };
          break;
        case "required":
          creatingValues[name] = Boolean(value);
          creatingValidation[name] = {
            value: creatingValues[name],
            message: message && !isEmpty(message) ? message : getMessage(t, name, value)
          };
          break;
        case "min":
        case "max":
        case "maxLength":
        case "minLength":
          creatingValues[name] = isString(value) ? parseFloat(value) : value;
          creatingValidation[name] = {
            value: creatingValues[name],
            message: message && !isEmpty(message) ? message : getMessage(t, name, value)
          };
          break;
        case "minFiles":
          innerCustomValidations = {
            ...innerCustomValidations,
            minFiles: (currentValue) => {
              if (!isArray(currentValue) || currentValue.length < (isString(value) ? parseInt(value) : value)) {
                return message && !isEmpty(message) ? message : getMessage(t, name);
              }
            }
          };
          break;
        case "maxFiles":
          innerCustomValidations = {
            ...innerCustomValidations,
            maxFiles: (currentValue) => {
              if (!isArray(currentValue) || currentValue.length > (isString(value) ? parseInt(value) : value)) {
                return message && !isEmpty(message) ? message : getMessage(t, name);
              }
            }
          };
          break;
        case "maxFileWeight":
        case "fileType":
          break;
        default:
          throw Error(`Unknown validation: ${name}`);
      }
    });
    creatingValidation.validate = {
      ...innerCustomValidations,
      ...customValidations ?? {}
    };
    return [creatingValidation, creatingValues];
  }, [backendValidation, customValidations, getValues, t]);
  const getErrorMessage = useCallback(
    (e) => {
      return e.message && !isEmpty(e.message) ? e.message : getMessage(
        t,
        e.type,
        e.type in values ? values[e.type] : void 0
      );
    },
    [t, values]
  );
  return useMemo(
    () => ({ validation, getErrorMessage }),
    [validation, getErrorMessage]
  );
};
export {
  getMessage,
  useFormValidation
};
